


import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { Moment } from 'moment';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import HorizontalMenu from '@/_modules/controls/components/horizontal-menu/horizontal-menu.vue';
import HorizontalMenuItem from '@/_modules/controls/components/horizontal-menu-item/horizontal-menu-item.vue';
import HorizontalMenuArrowLeft
  from '@/_modules/controls/components/horizontal-menu-arrow-left/horizontal-menu-arrow-left.vue';
import HorizontalMenuArrowRight
  from '@/_modules/controls/components/horizontal-menu-arrow-right/horizontal-menu-arrow-right.vue';
import { TContact } from '@/_types/contact.type';
import Avatar from '@/_components/avatar/avatar.vue';

@Component({
  name: 'promo-program-list-item',
  components: {
    HorizontalMenu,
    HorizontalMenuItem,
    HorizontalMenuArrowLeft,
    HorizontalMenuArrowRight,
    Avatar,
  }
})
export default class PromoProgramListItem extends Vue {

  @Prop({ type: Object })
  public readonly program: TConferenceProgram;

  public now: Moment;
  public visibleSpeakers: TContact[] = [];
  public firstVisibleSpeakerIndex: number = 0;
  public isFavorite: boolean = false;

  public created(): void {
    this.now = this.$moment();
    this.isFavorite = !!(this.program && this.program.is_favorite);
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get isNow(): boolean {
    const dateStartMoment = this.dateStartMoment;
    const dateEndMoment = this.dateEndMoment;
    if (!dateStartMoment || !dateEndMoment) {
      return false;
    }
    return this.now.isBetween(dateStartMoment, dateEndMoment);
  }

  public get programIdParam(): number {
    return this.$route.params.programId ? parseInt(this.$route.params.programId, 10) : null;
  }

  public get dateStartMoment(): Moment {
    return (this.program && this.program.date_start) ? this.$moment(this.program.date_start.replace('Z', '')) : null;
  }

  public get dateEndMoment(): Moment {
    return (this.program && this.program.date_end) ? this.$moment(this.program.date_end.replace('Z', '')) : null;
  }

  public get timeRange(): string {
    const dateStartMoment = this.dateStartMoment;
    const dateEndMoment = this.dateEndMoment;
    let result = '';
    if (dateStartMoment) {
      result += dateStartMoment.format(DateTimeFormat.HH_MM);
    }
    if (dateEndMoment) {
      if (result) {
        result += ' - ';
      }
      result += dateEndMoment.format(DateTimeFormat.HH_MM);
    }
    return result;
  }

  public get isSpeakersMenuArrowsVisible(): boolean {
    if (!this.program || !this.program.speakers) {
      return false;
    }
    return this.program.speakers.length > 3;
  }

  public get isSpeakersMenuArrowLeftDisabled(): boolean {
    if (!this.program || !this.program.speakers) {
      return true;
    }
    return this.firstVisibleSpeakerIndex < 1;
  }

  public get isSpeakersMenuArrowRightDisabled(): boolean {
    if (!this.program || !this.program.speakers) {
      return true;
    }
    return this.firstVisibleSpeakerIndex >= this.program.speakers.length - 3;
  }

  public toggleFavorite(event: MouseEvent): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();

    this.$store.dispatch('promoProgramStore/toggleFavorite', {
      eventId: this.eventId,
      conferenceRoomId: this.program.conference_id,
      programId: this.program.id,
      isFavorite: this.isFavorite,
    });
  }

  public onSpeakersMenuArrowLeftClick(): void {
    if (!this.program || !this.program.speakers || this.firstVisibleSpeakerIndex < 1) {
      return;
    }
    this.firstVisibleSpeakerIndex--;
    this.setVisibleSpeakers();
  }

  public onSpeakersMenuArrowRightClick(): void {
    if (
      !this.program
      || !this.program.speakers
      || this.firstVisibleSpeakerIndex >= this.program.speakers.length - 3
    ) {
      return;
    }
    this.firstVisibleSpeakerIndex++;
    this.setVisibleSpeakers();
  }

  @Watch('program', { immediate: true })
  private onProgramChange(): void {
    this.firstVisibleSpeakerIndex = 0;
    this.setVisibleSpeakers();

    this.isFavorite = !!(this.program && this.program.is_favorite);
  }

  private setVisibleSpeakers(): void {
    this.visibleSpeakers = [];

    if (!this.program || !this.program.speakers || !this.program.speakers.length) {
      return;
    }

    const speakers = this.program.speakers;
    if (speakers.length <= 3) {
      this.visibleSpeakers = [ ...speakers ];
      return;
    }

    if (this.firstVisibleSpeakerIndex >= speakers.length - 3) {
      this.visibleSpeakers = speakers.slice(speakers.length - 3);
    } else if (this.firstVisibleSpeakerIndex < 1) {
      this.visibleSpeakers = speakers.slice(0, 3);
    } else {
      this.visibleSpeakers = speakers.slice(
        this.firstVisibleSpeakerIndex,
        this.firstVisibleSpeakerIndex + 3,
      );
    }
  }

}
